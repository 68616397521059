.App {
  
}

.nav-link{
  color: #e7e7e7 !important;
}

.card-body{
  text-align: center;
}

.card2 {
  width: 500px;
  max-width: 100%;
} 

.imgsub {
  height: 50px;
  max-height: 240px;
}

.card2labelcontainer {
  display: flex;
}

.bg-dark2{
  background-color: #2f577a;
}

.parallax {
  /* The image used */
  background-image: url("/public/mmm-screenshot.png");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  
}


.contactform{
  max-width: 500px;
}

.carousel{
  height: auto;
}

.navbartop {
  color: rgb(192, 41, 66);
  background-color: rgb(192, 41, 66) !important;
}

.toplinks{ 
  padding-right: 50px;
}

.toplogo{
  padding-left: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mycontent{
  padding-left: 20px;
  padding-right: 20px;
  align-content: center;
}

.gameimage {
  max-width: 100%;
  max-height: 729px;
  background-color: rgba(231, 110, 147, 0.815);
text-align: center;
}
.gameimagelogo {
  
  max-width: 350px;
  max-height: 100px;
}

.purple2{
  background-color: rgb(245, 173, 245);
}

.blue{
  background-color: rgb(147, 218, 241);
}

.yellow{
  background-color: #d1eb5e;
}

.gamerow{
  padding-left: 12px;
  padding-right: 12px;
}

.mycol{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pink{
  max-height: 729px;
  max-width: 100%;
  background-color: rgba(231, 110, 147, 0.815);
}


.green{
  max-height: 729px;
  max-width: 100%;
  background-color: rgba(154, 224, 136, 0.815);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        height: 1rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      
/*
flip card
*/
.card-flip > div {
  backface-visibility: hidden;
  transition: transform 300ms;
  transition-timing-function: linear;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
}

.card-flip:hover .card-front {
  transform: rotateY(-180deg);
}
  
.card-flip:hover .card-back {
  transform: rotateY(0deg);
}

.card-top {
  height: 18.5vw;
  width: auto;
  
}

.card-img-top {
  vertical-align: middle !important;
}

@media screen and (max-width: 780px) {
  .card-img-ss {
    vertical-align: middle !important;
    max-height: 19.0vw;
    width: auto !important;
    cursor: pointer;
  }
}

@media screen and (min-width: 780px) {
  .card-img-ss {
    vertical-align: middle !important;
    height: 5.0vw;
    max-height: 134px;
    width: auto !important;
    cursor: pointer;
  }
}


@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800);
.service-39 {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad;
  font-weight: 300;
}

@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.service-14 {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad;
  font-weight: 300;
}

.service-14 h1, .service-14 h2, .service-14 h3, .service-14 h4, .service-14 h5, .service-14 h6 {
  color: #3e4555;
}

.service-14 h3 {
	  line-height: 36px;
    font-size: 30px;
}

.service-14 h6 {
	  line-height: 22px;
    font-size: 18px;
}

.service-14 .badge {
	line-height: 14px;
}

.service-14 .badge-info {
	background: #188ef4;
}

.service-14 .font-weight-medium {
	font-weight: 500;
}

.service-14 a {
	text-decoration: none;
}

.service-14 .linking {
	color: #3e4555;
}

.service-14 .linking:hover {
	color: #316ce8;
}

.service-14 .btn-info-gradiant {
		background: #188ef4;
    background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
    background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
    background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.service-14 .btn-info-gradiant:hover {
		background: #316ce8;
    background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
    background: -webkit-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
    background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
    background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
    background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.service-14 .btn-md {
    padding: 15px 45px;
    font-size: 16px;
}
